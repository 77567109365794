import React from 'react';
import styled from '@emotion/styled';
import breakpoints from './Theme/breakpoints';
import KeyboardArrowDownRounded from './Icons/KeyboardArrowDownRounded';

const ReadMoreWrapper = styled.div<{
  isHidden: boolean;
  positionStatic: boolean;
}>`
  position: ${(properties) => (properties.positionStatic
    ? 'static'
    : 'absolute'
  )};
  width: ${(properties) => (properties.positionStatic
    ? 'auto'
    : '100vw'
  )};
  left: 0;
  bottom: 0;
  display: ${(properties) => (properties.isHidden
    ? 'none'
    : 'flex'
  )};
  opacity: ${(properties) => (properties.isHidden
    ? 0
    : 1
  )};
  transition: display 0s, opacity 0.5s ease-out;
  height: auto;
  justify-content: center;
  font-size: 4rem;
  @media(min-width: ${breakpoints.sm}px) {
    display: none;
  }
`;

const ReadMoreArrow = (
  {
    onClick,
    isHidden,
    positionStatic,
  }: {
    onClick: () => void;
    isHidden: boolean;
    positionStatic?: boolean;
  },
) => (
  <ReadMoreWrapper
    onClick={onClick}
    isHidden={isHidden}
    positionStatic={Boolean(positionStatic)}
  >
    <KeyboardArrowDownRounded
      color="primary"
      fontSize="inherit"
      titleAccess="Scroll down to read more"
    />
  </ReadMoreWrapper>
);

export default ReadMoreArrow;
