import React from 'react';
import styled from '@emotion/styled';
import PropTypes, { InferProps } from 'prop-types';
import { RelativeContainer } from './Card';
import { Grid } from './Grid';
import { List } from './List';
import Chip from './Chip';
import Label from './Label';
import Headline2 from './Typography/Heading/Headline2';
import Body2 from './Typography/Body/Body2';
import SubTitle from './Typography/SubTitle';
import ArticleBody from './InnerHtmlStyling';
import Labels from './Card/Labels';
import breakpoints from './Theme/breakpoints';
import ReadMoreArrow from './ReadMoreArrow';
import Skeleton from './SkeletonLoader/Skeleton';
import { mediumRoundedCorners } from './RoundedCorners';
import useCompressOnScroll from './lib/useCompressOnScroll';
import useScrollToContent from './lib/useScrollToContent';

export const PartnersArticleContainer = styled.div`
  && {
    a {
      color: var(--black-high);
    }
  }
`;

const ArticleHeadline = styled(Headline2)`
  && {
    margin-bottom: 8px;

    @media (min-width: ${breakpoints.md}px) {
      margin-bottom: 20px;
    }
  }
`;

export const ArticleGrid = styled(Grid)`
  &&& {
    max-width: 1100px;
    padding: 0 8px 16px;

    @media (min-width: ${breakpoints.md}px) {
      padding: 0 12px;
    }
  }
`;

const ArticleContainer = styled.div`
  display: block;
  background-color: #fff;

  img {
    width: 100%;
  }
  figure {
    margin: 0;
  }
  && figcaption {
    color: var(--grey-dark);
    font-family: var(--font-article);
    padding: 0 1px;
    text-align: left;
    line-height: 1.25rem;
    font-size: 12px;
  }
  .image-container {
    margin-bottom: 16px;
    ${mediumRoundedCorners};
    overflow: hidden;
  }
  .image-container + figcaption {
    margin: -16px 0 16px 8px;
  }
`;

const HeaderImageContainer = styled(RelativeContainer)`
  background: rgb(255,255,255);
  background: linear-gradient(180deg,
  rgba(255,255,255,1) 0%, rgba(187,187,187,1) 68%);

  padding-bottom: calc(66.67% + 18px);
  width: calc(100% + 32px);
  left: -16px;
  @media (min-width: ${breakpoints.sm}px) {
    padding-bottom: 66.67%;
    left: auto;
    width: auto;
  }
`;

const TextContainer = styled.div`
  padding: 8px 0 20px;
  max-width: 550px;
  margin: 24px auto 0;

  @media (min-width: ${breakpoints.md}px) {
    padding: 12px 24px 24px;
    margin: 0 auto;
  }
`;

const CreatedBy = styled(SubTitle)`
  && a {
    color: var(--grey-dark);
  }
`;

const ArticleFlexSubContainer = styled.div`

  @media (min-width: ${breakpoints.xssm}px) {
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
  }

  @media (min-width: ${breakpoints.md}px) {
    margin-bottom: 0;
  }
`;

const ArticleContentAnchor = styled.span`
  height: 0;
`;

const ArticleHeader = styled.header`
  display: flex;
  flex-direction: column;
`;

interface ArticleHeaderTextProperties { fullHeightTitle: boolean }
const ArticleHeaderText = styled.div<ArticleHeaderTextProperties>`
  display: flex;
  flex-direction: column;
  ${(properties) => properties.fullHeightTitle && `
    min-height: calc(50vh - 100px);
    word-break: break-word;
    justify-content: flex-end;
  `}

  @media (min-width: ${breakpoints.sm}px) {
    min-height: auto;
    justify-content: flex-start;
  }
`;

interface ArticleSubContainerProperties {
  fullHeightTitle: boolean;
  scrollY: number;
}
const ARTICLE_SUBHEADER_HEIGHT = 48;
const ArticleSubContainer = styled.div<ArticleSubContainerProperties>`
  margin: 0;

  ${(properties) => properties.fullHeightTitle && `
    min-height: calc(50vh - ${
  properties.scrollY}px - ${
  ARTICLE_SUBHEADER_HEIGHT}px);
    word-break: break-word;
    transition: min-height 0.05s linear;
  `}

  @media (min-width: ${breakpoints.sm}px) {
    min-height: auto;
  }

  @media (min-width: ${breakpoints.md}px) {
    order: 1;
    min-width: 550px;
    margin: 36px auto 0;
  }
`;

const ArticleLabel = styled(SubTitle)`
  && {
    margin: 0;
    color: var(--black-high);
  }
`;

export const ImageContainer = styled.div`
  width: 102px;
  margin-right: 16px;
`;

export const StyledList = styled(List)`
  && {
    background-color: var(--white-light);
  }
`;

export const StyledBody2 = styled(Body2)`
  && {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: vertical;
  }
`;

export const TagChip = styled(Chip)`
  && {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: .75rem;
  }
`;

const SocialButtons = styled.div`
  margin: -6px 0 6px -12px;
  height: 40px;

  @media(min-width: ${breakpoints.xssm}px) {
    margin-left: 0;
    height: 60px;
    width: 200px;
    overflow: visible;
  }
`;

const Date = styled(SubTitle)`
  && {
    margin: 0;
  }
`;

const TitleSkeleton = styled(Skeleton)`
  min-height: 200px;

  @media (min-width: ${breakpoints.sm}px) {
    min-height: auto;
  }
`;

const CreatedBySkeleton = styled(Skeleton)`
  min-height: 44px;

  @media (min-width: ${breakpoints.sm}px) {
    min-height: auto;
  }
`;

const SocialSkeleton = styled(Skeleton)`
  min-height: 40px;

  @media (min-width: ${breakpoints.sm}px) {
    min-height: auto;
  }
`;

interface DynamicShareButtonContainerProperties { center: boolean }
export const DynamicShareButtonContainer = styled.div<
DynamicShareButtonContainerProperties
>`
  text-align: ${(properties) => properties.center && 'center'};
  height: 40px;
  margin: 32px 0;
`;

const articleSubHeaderPropertyTypes = {
  insertedAt: PropTypes.element,
  articleBy: PropTypes.node,
  children: PropTypes.node,
  socialButtons: PropTypes.node,
  sponsorMessage: PropTypes.string,
  pressReleaseMessage: PropTypes.string,
  fullHeightTitle: PropTypes.bool,
  showLoader: PropTypes.bool,
};

type ArticleSubHeaderProperties = InferProps<
  typeof articleSubHeaderPropertyTypes
>;

export const ArticleSubHeader = ({
  articleBy,
  insertedAt,
  children,
  socialButtons,
  pressReleaseMessage,
  sponsorMessage,
  fullHeightTitle,
  showLoader,
} : ArticleSubHeaderProperties) => {
  const { articleContentReference, scrollToContent } = useScrollToContent();
  const scrollY = useCompressOnScroll();

  return (
    <>
      <ArticleSubContainer
        fullHeightTitle={Boolean(fullHeightTitle)}
        scrollY={scrollY}
      >
        {sponsorMessage && showLoader ? (
          <ArticleLabel tag="span">
            <Skeleton variant="rectangular" width="100px" />
          </ArticleLabel>
        ) : <ArticleLabel tag="span">{sponsorMessage}</ArticleLabel>}

        {pressReleaseMessage && showLoader ? (
          <ArticleLabel tag="span">
            <Skeleton variant="rectangular" width="100px" />
          </ArticleLabel>
        ) : <ArticleLabel tag="span">{pressReleaseMessage}</ArticleLabel>}

        <ArticleFlexSubContainer>
          {showLoader ? (
            <CreatedBy tag="div">
              <CreatedBySkeleton
                variant="rectangular"
                width="100px"
                height="100%"
              />
            </CreatedBy>
          ) : (
            <CreatedBy tag="div">
              {articleBy && articleBy}
              <Date tag="p" variant="subtitle2">
                {insertedAt}
              </Date>
            </CreatedBy>
          )}
          {children}
          {showLoader ? (
            <SocialButtons>
              <SocialSkeleton
                variant="rectangular"
                width="100%"
                height="100%"
              />
            </SocialButtons>
          ) : <SocialButtons>{socialButtons}</SocialButtons>}
        </ArticleFlexSubContainer>
        <ArticleContentAnchor ref={articleContentReference} />
      </ArticleSubContainer>
      {fullHeightTitle && (
        <ReadMoreArrow
          onClick={scrollToContent}
          isHidden={scrollY > 1}
        />
      )}
    </>
  );
};

ArticleSubHeader.propTypes = articleSubHeaderPropertyTypes;

const articlePropertyTypes = {
  title: PropTypes.string,
  category: PropTypes.shape({
    title: PropTypes.string,
  }),
  children: PropTypes.node,
  translatedCategoryTitle: PropTypes.string,
  articleBy: PropTypes.node,
  insertedAt: PropTypes.element,
  body: PropTypes.string,
  image: PropTypes.node,
  imageCaption: PropTypes.string,
  socialButtons: PropTypes.node,
  labels: PropTypes.arrayOf(PropTypes.string),
  sponsorMessage: PropTypes.string,
  pressReleaseMessage: PropTypes.string,
  fullHeightTitle: PropTypes.bool,
  showLoader: PropTypes.bool,
};

type ArticleProperties = InferProps<
  typeof articlePropertyTypes
>;

const Article = ({
  category,
  translatedCategoryTitle,
  title,
  articleBy,
  insertedAt,
  body,
  image,
  imageCaption,
  children,
  socialButtons,
  labels,
  sponsorMessage,
  pressReleaseMessage,
  fullHeightTitle,
  showLoader,
} : ArticleProperties) => {
  const isFullHeightTitle = Boolean(fullHeightTitle ?? true);

  return (
    <ArticleContainer>
      <ArticleHeader>
        <ArticleHeaderText fullHeightTitle={isFullHeightTitle}>
          {showLoader ? (
            <ArticleHeadline tag="h1">
              <TitleSkeleton variant="rectangular" width="100%">
                &nbsp;
                <br />
                &nbsp;
              </TitleSkeleton>
            </ArticleHeadline>
          ) : <ArticleHeadline tag="h1">{title}</ArticleHeadline>}
        </ArticleHeaderText>
        <ArticleSubHeader
          fullHeightTitle={isFullHeightTitle}
          articleBy={articleBy}
          insertedAt={insertedAt}
          socialButtons={socialButtons}
          sponsorMessage={sponsorMessage}
          pressReleaseMessage={pressReleaseMessage}
          showLoader={showLoader}
        />
        {showLoader ? (
          <HeaderImageContainer>
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </HeaderImageContainer>
        ) : (
          image && (
          <figure>
            <HeaderImageContainer>
              {image}
              <Label
                className="highlight"
              >
                {translatedCategoryTitle ?? category?.title}
                <Labels labels={labels} />
              </Label>
            </HeaderImageContainer>
            {imageCaption && (
              // eslint-disable-next-line react/no-danger
              <figcaption dangerouslySetInnerHTML={{ __html: imageCaption }} />
            )}
          </figure>
          ))}
      </ArticleHeader>
      <TextContainer>
        {showLoader ? (
          <ArticleBody>
            <Skeleton variant="rectangular" width="100%" height="100vh" />
          </ArticleBody>
        ) : (
          <ArticleBody
            dangerouslySetInnerHTML={{ __html: body }}
          />
        )}
      </TextContainer>
      {children}
    </ArticleContainer>
  );
};

Article.propTypes = articlePropertyTypes;

export default Article;
