import { useCallback, useRef } from 'react';

const useScrollToContent = () => {
  const articleContentReference = useRef<HTMLDivElement | null>(null);

  const scrollToContent = useCallback(() => {
    articleContentReference.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return {
    articleContentReference,
    scrollToContent,
  };
};

export default useScrollToContent;
