import { useEffect, useState, useCallback } from 'react';

const debounce = (handler: () => void): (() => void) => {
  let ticking = false;

  return () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        handler();
        ticking = false;
      });
      ticking = true;
    }
  };
};

const useCompressOnScroll = () => {
  const [scrollY, setScrollY] = useState<number>(
    typeof window === 'undefined' ? 0 : window.scrollY,
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(debounce(() => {
    setScrollY(window.scrollY);
  }), [setScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return scrollY;
};

export default useCompressOnScroll;
